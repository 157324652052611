<template>
  <div>
    <b-card-text>
      <b-row>
        <b-col cols="12">
          <!-- Operatör -->
          <b-form-group>
            <v-select
              v-model="filter_values.selectedtOperator"
              :options="operators"
              :placeholder="lang('t_operator')"
              :reduce="(val) => val"
              label="display_name"
            >
            </v-select>
          </b-form-group>
          <!-- Proje -->
          <b-form-group>
            <v-select
              v-model="filter_values.selectedtProject"
              :options="projects"
              :reduce="(val) => val"
              label="display_name"
              :placeholder="lang('t_project')"
            >
            </v-select>
          </b-form-group>
          <!-- Dosya Yükleme -->
          <b-form-group>
            <b-form-file
              v-model="files"
              :placeholder="lang('t_uploadFile')"
              :drop-placeholder="lang('t_uploadFile')"
              no-drop
            />
          </b-form-group>
          <!-- Gönder Butonu -->
          <b-button
            @click="read_sms_data"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            style="float: right"
          >
            {{ lang("t_send") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardText, BLink, BRow, BCol, BFormGroup, BFormCheckbox, BFormInput, BButton, BFormFile
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BLink,
    BBadge,
    BCardText,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    BFormFile
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      filter_values: {
        selectedtOperator: [],
        selectedtProject: [],
        selectedtOperator: ''

      },
      operators: [],
      projects: [],
      files: {},
      data: [],
      smsexceldata: [],
    }
  },
  mounted: function () {

    this.get_project();
    this.get_smssettings();
  },
  methods: {
    get_project: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/Project`)
      ).data;
      this.projects = response;
    },
    get_smssettings: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/SmsSettings`)
      ).data;

      this.operators = response;
    },
    async read_sms_data() {
      this.smsexceldata = [];
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = globalThis.XLSX.read(bstr, { type: 'binary' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = globalThis.XLSX.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
        });
        this.data = this.xlsxToJson(data);
        let excelphones = '',
          excelmessages = '';
        for (const item of this.data) {
          this.smsexceldata.push({
            phones: this.filter_values.selectedtOperator.prefix ? this.filter_values.selectedtOperator.prefix : '90' + item['TELEFON'].substr(-10),
            messages: item['MESAJ'].trim(),
          });
        }

        this.$swal({
          title: this.lang('t_areYouSure'),
          text: this.smsexceldata.length + " adet mesaj gönderilecek onaylıyor musunuz?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.lang('t_yes'),
          cancelButtonText: this.lang('t_cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',

          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.send_excel_sms();
          }
        })

      };
      reader.readAsBinaryString(this.files);
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },
    send_excel_sms: async function () {
      this.excel_dialog = false;
      var response = '';
      for (const row of this.smsexceldata) {
        response = (
          await this.$http_in.post(
            `spv/v1/SendSms`,
            {
              data: row,
              sending_info: {
                sms_settings: this.filter_values.selectedtOperator,
                selected_project: {
                  internal_name: this.files.name
                },
                selected_sms_template: {
                  internal_name: ''
                },
                selected_filter: {
                  internal_name: ''
                },
                collection: `${this.filter_values.selectedtProject.internal_name}_customers`,
              }
            }
          )
        ).data;
      }

      if (response.status == 'success') {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          text: this.lang('t_messagesHaveBeenDeliveredSuccessfully'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });

      } else {
        this.$swal({
          icon: 'warning',
          title: this.lang('t_warning'),
          text: this.lang('t_thereWasAProblemDeliveringYourMessages'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }
      this.files = '';

    },
  }
}
</script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
