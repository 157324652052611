<template>
  <div>
    <b-row class="mb-1 mx-0" style="overflow-x: auto; white-space: nowrap">
      <b-button
        style="width: 130px"
        class="ml-1"
        :variant="
          selectedButton != 'sender' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'sender'"
      >
        {{ lang("t_smsSending") }}
      </b-button>
      <b-button
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'template' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'template'"
      >
        {{ lang("t_pattern") }}
      </b-button>
      <b-button
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'filter' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'filter'"
      >
        {{ lang("t_filter") }}
      </b-button>
      <b-button
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'stt' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'stt'"
      >
        {{ "STT" }}
      </b-button>
      <b-button
        style="width: 130px"
        class="float-right ml-auto mr-1 ml-50"
        :variant="
          selectedButton != 'settings' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'settings'"
      >
        {{ lang("t_settings") }}
      </b-button>
    </b-row>

    <b-card>
      <!-- <sms-sender v-if="selectedButton == 'sender'"></sms-sender> -->
      <sms-template v-if="selectedButton == 'template'"></sms-template>
      <sms-filter v-if="selectedButton == 'filter'"></sms-filter>
      <sms-settings v-if="selectedButton == 'settings'"></sms-settings>
      <sms-sender v-if="selectedButton == 'sender'"></sms-sender>
      <stt v-if="selectedButton == 'stt'"></stt>
    </b-card>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BButton,
} from "bootstrap-vue";
import SmsSender from "./SmsSender/Sender.vue";
import SmsFilter from "./SmsFilter/list/List.vue";
import SmsSettings from "./SmsSettings/list/List.vue";
import SmsTemplate from "./SmsTemplate/list/List.vue";
import Stt from "./STT/list/List.vue";


export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      selectedButton: "sender",
      optionsButton: [
        { text: globalThis._lang("t_smsSending"), value: "sender" },
        { text: globalThis._lang("t_smsTemplate"), value: "template" },
        { text: globalThis._lang("t_smsFilter"), value: "filter" },
        { text: globalThis._lang("t_settings"), value: "settings" },
        { text: 'STT', value: "stt" },
      ],
    };
  },
  components: {
    Stt,
    SmsFilter,
    SmsTemplate,
    SmsSettings,
    SmsSender,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
  },
};
</script>

<style>
</style>
