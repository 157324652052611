<template>
  <div>
    <b-card-text>
      <b-row>
        <b-col cols="12">
          <b-row class="mx-0 mb-1">
            <b-form-radio
              @input="filter_values.selected_operator = ''"
              value="sms"
              v-model="filter_values.type"
              >SMS
            </b-form-radio>
            <b-form-radio
              @input="filter_values.selected_operator = ''"
              class="ml-3"
              value="whatsapp"
              v-model="filter_values.type"
              >Whatsapp
            </b-form-radio>
          </b-row>

          <!-- Operatör -->
          <b-form-group>
            <v-select
              v-if="filter_values.type == 'sms'"
              v-model="filter_values.selected_operator"
              :options="operators"
              :placeholder="lang('t_operator')"
              :reduce="(val) => val"
              label="display_name"
            >
            </v-select>
            <v-select
              v-else
              v-model="filter_values.selected_operator"
              :options="wp_providers.filter((e) => e.status)"
              :placeholder="lang('t_whatsapp')"
              :reduce="(val) => val"
              label="display_name"
            >
            </v-select>
          </b-form-group>
          <!-- Proje -->

          <b-form-group>
            <v-select
              v-model="filter_values.selectedtProject"
              :options="projects"
              label="display_name"
              :reduce="(val) => val"
              :placeholder="lang('t_project')"
            >
            </v-select>
          </b-form-group>
          <!-- Telefon Numaraları -->
          <b-form-group
            :description="lang('t_enterPhoneNumbersSeparatedByCommas')"
          >
            <b-form-input
              id="helperInput"
              v-model="filter_values.selectedtNumbers"
              :placeholder="lang('t_phoneNumbers')"
            />
          </b-form-group>
          <b-form-group>
            <v-select
              @input="
                (item) => {
                  filter_values.selectedtMessage = item.message || '';
                }
              "
              v-if="filter_values.selectedtProject.internal_name != ''"
              :options="
                templates.filter((e) => {
                  return (
                    e.project == filter_values.selectedtProject.internal_name
                  );
                })
              "
              v-model="filter_values.selectedMessageTemplate"
              :reduce="(val) => val"
              :placeholder="lang('t_smsPattern')"
              label="display_name"
            >
            </v-select>
          </b-form-group>
          <!-- Mesaj Metni -->
          <b-form-group>
            <b-form-textarea
              id="message_text"
              trim
              rows="7"
              v-model="filter_values.selectedtMessage"
              :placeholder="lang('t_smsText')"
            />
          </b-form-group>
          <!-- Gönder Butonu -->
          <b-button
            variant="success"
            style="float: right"
            @click="special_dialog()"
          >
            {{ lang("t_send") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>

<script>
import {
  BFormRadioGroup, BBadge, BCard, BCardText, BLink, BRow, BCol, BFormGroup, BFormCheckbox, BFormInput, BButton, BFormFile, BFormTextarea, BFormRadio
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BLink,
    BBadge,
    BCardText,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    BFormFile,
    BFormTextarea,
    BFormRadio,
    BFormRadioGroup
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      filter_values: {
        type: 'sms',
        selected_operator: [],
        selectedtProject: [],
        selectedtNumbers: '',
        selectedtMessage: '',
      },
      wp_providers: [],
      templates: [],
      operators: [],
      projects: [],
      special_info: {
        phones: '',
        filtered_phones: '',
        message: '',
        length: '',
      },

    }
  },
  mounted: function () {
    this.get_project();
    this.GetSmsSettings();
    this.get_smstemplate();
    this.get_wp_providers();
  },
  methods: {
    get_wp_providers: async function () {
      var response = (
        await this.$http_in.get(`text-channel-wp/v1/WhatsappProvider`)
      ).data;
      console.log("WhatsappProvider", response);
      this.wp_providers = response;
    },
    get_smstemplate: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/SmsTemplate`)
      ).data;
      //this.columns = response;
      this.templates = response;
    },
    get_project: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/Project`)
      ).data;
      this.projects = response;
    },
    special_dialog() {
      if (this.filter_values.selectedtNumbers.length <= 0) {
        this.$swal({
          icon: 'error',
          title: this.lang('t_description'),
          text: this.lang('t_pleaseEnterAtLeastOnePhoneNumber'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      } else if (this.filter_values.selectedtMessage.length <= 0) {
        this.$swal({
          icon: 'error',
          title: this.lang('t_description'),
          text: this.lang('t_pleaseEnterAMessage'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-sucess',
          },
        });
      } else {
        let arr = this.filter_values.selectedtNumbers.split(',');
        let phones = arr
          .filter(function (e) {
            if (e != null && e != '' && e.length >= 10) {
              return e.trim();
            }
          })
          .map((e) => e.substr(-10));

        console.log("phones", phones);
        this.special_info.filtered_phones = phones.join('~');
        this.special_info.length = phones.length;
        //this.sp_dialog = true;

        this.$swal({
          title: this.lang('t_areYouSure'),
          text: this.special_info.length + this.lang('t_smscount'),
          //  {{ `${special_info.length} adet mesaj gönderilecektir. Onaylıyor musunuz?` }}
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.lang('t_yes'),
          cancelButtonText: this.lang('t_cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',

          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.send_special_sms();
          }
        });
      }
    },
    GetSmsSettings: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/SmsSettings`)
      ).data;

      this.operators = response;
    },
    async send_special_sms() {

      // var messages = "";
      // for (let index = 0; index < this.special_info.length; index++) {
      //   messages += this.filter_values.selectedtMessage + "~";
      // }

      var messages = this.filter_values.selectedtMessage;
      console.log("this.special_info.filtered_phones", this.special_info.filtered_phones);
      console.log("messages", messages);
      messages = messages.replace('{{vName}}', globalThis.user.name);
      messages = messages.replace('{{vSurname}}', globalThis.user.surname);
      messages = messages.replace('{{vDescription}}', globalThis.user.description);

      var response;
      if (this.filter_values.type == 'sms') {
        response = (
          await this.$http_in.post(
            `spv/v1/SendSms`,
            {
              data: {
                phones: this.special_info.filtered_phones,
                messages: messages,
              },
              sending_info: {
                selected_project: {
                  internal_name: 'Özel Gönderim'
                },
                selected_sms_template: {
                  internal_name: ''
                },
                selected_filter: {
                  internal_name: ''
                },
                sms_settings: this.filter_values.selected_operator,
                collection: this.filter_values.selectedtProject.internal_name + '_customers'
              }
            }
          )
        ).data;
      } else {
        response = (
          await this.$http_in.post(
            `text-channel-wp/v1/SendMessage`,
            {
              data: {
                phones: this.special_info.filtered_phones,
                messages: messages,
              },
              sending_info: {
                selected_project: {
                  internal_name: 'Whatsapp Özel Gönderim'
                },
                selected_sms_template: {
                  internal_name: ''
                },
                selected_filter: {
                  internal_name: ''
                },
                sms_settings: this.filter_values.selected_operator,
                collection: this.filter_values.selectedtProject.internal_name + '_customers'

              }
            }
          )
        ).data;
      }


      if (response.status == 'success') {
        // var deneme=1;
        // if (deneme == 0) {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          text: this.lang('t_messagesHaveBeenDeliveredSuccessfully'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      } else {
        this.$swal({
          icon: 'error',
          title: this.lang('t_AProblemOccurredwhileProcessing'),
          text: this.lang('t_thereWasAProblemDeliveringYourMessages'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }
    },
  }
}
</script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
