<template>
  <div>
    <b-card-text>
      <b-row>
        <b-col cols="12">
          <div class="mx-auto" max-width="100%">
            <b-card>
              <b-row>
                <b-col class="pb-0" cols="12">
                  <b-row>
                    <!-- Gönderim Seçenekleri -->
                    <b-col cols="12" class="mb-50">
                      <b-form-group>
                        <v-select
                          v-model="sender_info.type"
                          :options="types"
                          label="value"
                          :reduce="(item) => item.key"
                          :placeholder="lang('t_sendType')"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <system-sms v-if="sender_info.type == 'system_sms'"></system-sms>
              <external-sms
                v-if="sender_info.type == 'external_sms'"
              ></external-sms>
              <special-sms
                v-if="sender_info.type == 'special_sms'"
              ></special-sms>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BButton,
} from "bootstrap-vue";

import SystemSms from "./TransferSend/SystemSms.vue";
import ExternalSms from "./TransferSend/ExternalSms.vue";
import SpecialSms from "./TransferSend/SpecialSms.vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BLink,
    BBadge,
    BCardText,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    SystemSms,
    SpecialSms,
    ExternalSms,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      sender_info: {
        type: "",
      },
      types: [
        {
          key: "system_sms",
          value: globalThis._lang("t_sendFromVoyceCRM"),
        },
        {
          key: "external_sms",
          value: globalThis._lang("t_sendFromExternalCRM"),
        },
        {
          key: "special_sms",
          value: globalThis._lang("t_sendFromCustomSms"),
        },
      ],
    };
  },
};
</script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
